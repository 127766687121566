import {
    LOGIN_USER,
    GET_ERRORS,
    CLEAR_ERRORS,
    LOGOUT_USER,
} from "../constants/user";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwtDecode from "jwt-decode";
import { SERVER } from "../constants/config";

export const deleteUser = (params, callback) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };
    const formData = new FormData();
    formData.append("username", params.username);
    formData.append("created_by", params.created_by);

    return (dispatch) => {
        axios
            .post(`${SERVER}/user/delete`, formData, {
                headers: headers,
            })
            .then((res) => {
                callback(res.data);
            })
            .catch((err) => {
                // console.log(large)
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.detail,
                });
            });
    };
};

export const requestLoginUSer = (userData) => {
    // console.log(userData)
    // const params = {
    //   username: userData.username,
    //   password: userData.password,
    //   fingerprint: userData.fingerprint
    // }

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };
    const formData = new FormData();
    formData.append("username", userData.username);
    formData.append("password", userData.password);
    formData.append("fingerprint", userData.fingerprint);
    return (dispatch) => {
        axios
            .post(`${SERVER}/user/login`, formData, {
                headers: headers,
            })
            .then((res) => {
                // console.log(res.data.detail)
                if (typeof res.data.detail !== "string") {
                    const { token } = res.data.detail;
                    localStorage.setItem("jwtToken", token);
                    localStorage.setItem(
                        "userLogin",
                        JSON.stringify(res.data.detail)
                    );
                    setAuthToken(token);
                    const decoded = jwtDecode(token);
                    dispatch(actLoginUser(decoded));
                } else {
                    alert("Tài khoản hoặc mật khẩu sai gòi.");
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.detail,
                });
            });
    };
};

export const fetchUser = (callback) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };

    // console.log(id)

    return (dispatch) => {
        axios
            .get(`${SERVER}/app/users/detail`, {
                headers: headers,
                // params: params
            })
            .then((res) => {
                callback(res.data);
            })
            .catch((err) => {
                // dispatch({
                //   // type: GET_ERRORS,
                //   // payload: err.response
                // })
                console.log(err);
            });
    };
};

export const fetchUserType = (callback) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };
    return (dispatch) => {
        axios
            .get(`${SERVER}/users/type`, {
                headers: headers,
            })
            .then((res) => {
                callback(res.data);
            })
            .catch(console.log);
    };
};

export const createUser = (params, callback) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };

    // const username = decoded.username
    // const user_id = decoded.user_id
    const formData = new FormData();
    formData.append("full_name", params.userFullName);
    formData.append("password", params.password);
    formData.append("gender", params.userGender);
    formData.append("phone", params.userPhone);
    formData.append("dob", params.dob);
    formData.append("hired_date", params.hired_date);
    formData.append("user_position", params.userPosition);
    formData.append("username", params.username);
    formData.append("created_by", params.created_by);
    formData.append("FarmsInChargeValue", params.FarmsInChargeValue);
    formData.append("ZonesInChargeValue", params.ZonesInChargeValue);
    formData.append("image", params.userImage);

    // console.log(username)

    return (dispatch) => {
        axios
            .post(`${SERVER}/user/register`, formData, {
                headers: headers,
            })
            .then((res) => {
                callback(res.data);
            })
            .catch((err) => {
                // console.log(large)
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.detail,
                });
            });
    };
};
export const deactiveUser = (params, callback) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };

    // const username = decoded.username
    // const user_id = decoded.user_id
    const formData = new FormData();
    formData.append("username", params.deactiveuser);
    formData.append("updated_by", params.updated_by);

    return (dispatch) => {
        axios
            .post(`${SERVER}/user/deactive`, formData, {
                headers: headers,
            })
            .then((res) => {
                callback(res.data);
            })
            .catch((err) => {
                // console.log(large)
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.detail,
                });
            });
    };
};

export const activeUser = (params, callback) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };

    // const username = decoded.username
    // const user_id = decoded.user_id
    const formData = new FormData();
    formData.append("username", params.activeuser);
    formData.append("updated_by", params.updated_by);

    return (dispatch) => {
        axios
            .post(`${SERVER}/user/active`, formData, {
                headers: headers,
            })
            .then((res) => {
                callback(res.data);
            })
            .catch((err) => {
                // console.log(large)
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.detail,
                });
            });
    };
};

export const passwordUser = (params, callback) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };

    // const username = decoded.username
    // const user_id = decoded.user_id
    const formData = new FormData();
    formData.append("username", params.passworduser);
    formData.append("password", params.oldPassword);
    formData.append("newpassword", params.password);
    formData.append("updated_by", params.updated_by);

    return (dispatch) => {
        axios
            .post(`${SERVER}/user/password`, formData, {
                headers: headers,
            })
            .then((res) => {
                callback(res.data);
            })
            .catch((err) => {
                // console.log(large)
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.detail,
                });
            });
    };
};

export const updateUser = (params, callback) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("jwtToken"),
        fingerprint: "123456",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    };

    // const username = decoded.username
    // const user_id = decoded.user_id
    const formData = new FormData();
    formData.append("full_name", params.userFullName);
    formData.append("gender", params.userGender);
    formData.append("phone", params.userPhone);
    formData.append("dob", params.dob);
    formData.append("hired_date", params.hired_date);
    formData.append("user_position", params.userPosition);
    formData.append("username", params.username);
    formData.append("created_by", params.created_by);
    formData.append("FarmsInChargeValue", params.FarmsInChargeValue);
    formData.append("ZonesInChargeValue", params.ZonesInChargeValue);
    formData.append("image", params.userImage);

    // console.log(username)

    return (dispatch) => {
        axios
            .post(`${SERVER}/user/update`, formData, {
                headers: headers,
            })
            .then((res) => {
                callback(res.data);
            })
            .catch(console.log);
    };
};

export const logout = () => {
    return (dispatch) => {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("userLogin");
        setAuthToken(false);
        // dispatch(actLoginUser({}));
        dispatch({ type: LOGOUT_USER });
    };
};

export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};

export const actLoginUser = (decoded) => {
    return {
        type: LOGIN_USER,
        payload: decoded,
    };
};
